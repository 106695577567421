import { BiErrorCircle } from 'react-icons/bi'
import { ErrorComponentInterface } from '../../../utils/typings/Shared'


function ErrorComponent({ text, className }:ErrorComponentInterface) {
    return (
        <div className={`text-center text-red ${className}`}>
            <BiErrorCircle size={40} className='mx-auto'/>
            <p className='mt-5'>{ text }</p>
        </div>
    )
}

export default ErrorComponent